import { ProductContent } from "@sphtech/dmg-design-system";
import {
  ResolvedCUEElement,
  ResolvedProductListing,
} from "@src/app/layouts/Article/elements";
import Arrow from "@src/app/theme/icons/Arrow";
import amazonPricingDataLoader from "@src/app/utils/amazonPricingDataloader";
import { formatPrice } from "@src/app/utils/product";
import { Fragment, ReactNode, useState } from "react";
import useSWR from "swr";

import styles from "./product.module.css";
import productDataDataLoader from "./productDataLoader";

type Props = {
  element: ResolvedProductListing;
  allElements: ResolvedCUEElement[];
};

export default function ProductIndex({
  element,
  allElements,
}: Props): ReactNode {
  const [showMore, setShowMore] = useState(false);
  const filterProduct = allElements
    .filter((item) => item.relation?.type === "product")
    .map((item) => item.relation?.id);

  const { data, isLoading } = useSWR(filterProduct, (keys) =>
    productDataDataLoader.loadMany(keys).then((data) => {
      return data.map((data) => (data instanceof Error ? null : data));
    }),
  );

  const asins = data
    ?.flatMap((item) => item?.dealsSource.map((deal) => deal.dynamicPricing))
    .filter((id) => id);

  const { data: extractedAmazonData } = useSWR(asins, () => {
    const response =
      asins &&
      amazonPricingDataLoader
        .loadMany(asins)
        .then((resolvedData) =>
          resolvedData.map((data) => (data instanceof Error ? null : data)),
        );
    return response;
  });

  const itemsPerPage = element.additionalFields.items_per_page.value || false;
  const hasMoreItemsToShow =
    itemsPerPage && data?.length && data.length > itemsPerPage;

  const showMoreHandler = () => {
    setShowMore(!showMore);
  };

  return (
    <Fragment>
      {!isLoading && (
        <div className={styles.bodyContainer}>
          <div className={styles.indexContainer}>
            <h2 className={styles.indexTitle}>
              {element.additionalFields.title.value}
            </h2>
            <div className={styles.indexItemContainer}>
              {data?.map((item, index) => (
                <div
                  className={styles.indexItem}
                  key={index}
                  style={{
                    display:
                      itemsPerPage && index > itemsPerPage - 1 && !showMore
                        ? "none"
                        : "",
                  }}
                >
                  {item && (
                    <ProductContent
                      {...item}
                      cons={undefined}
                      pros={undefined}
                      discount=""
                      dealsSource={item.dealsSource.map((deal) => ({
                        ...deal,
                        discountPrice:
                          (extractedAmazonData &&
                            formatPrice(
                              extractedAmazonData.find(
                                (amazonData) =>
                                  amazonData &&
                                  amazonData.asin === deal.dynamicPricing,
                              )?.discountPrice,
                            )) ||
                          deal.discountPrice,
                      }))}
                    />
                  )}
                </div>
              ))}
            </div>
            {hasMoreItemsToShow && (
              <button
                onClick={() => {
                  showMoreHandler();
                }}
              >
                {showMore ? "SHOW LESS" : "SHOW MORE"}
                <Arrow
                  direction={showMore ? "up" : "down"}
                  stroke="#000"
                  width={9}
                />
              </button>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export { productDataDataLoader };
