import { RoutePathsType } from "../types/routePaths";

export const RoutePaths: RoutePathsType = {
  NavBar: {
    primaryLinks: [
      {
        name: "Interior Design",
        url: "/interior-design",
      },
      {
        name: "Property",
        url: "/property",
      },
      {
        name: "Finance",
        url: "/finance",
      },
      {
        name: "Renovation",
        url: "/renovation",
      },
      {
        name: "Shopping",
        url: "/shopping",
      },
      {
        name: "How To",
        url: "/how-to",
      },
      // {
      //   name: "Ask The Experts",
      //   url: "/ask-the-experts",
      // },
      // TMP: Hide Subscribe DMG-3632
      // {
      //   id: "magazineIssues", // allow overwrite of name and url from data
      //   name: "Magazine",
      //   url: "https://subscribe.sph.com.sg/magazines/",
      //   variant: "primary",
      //   as: "button",
      // },
    ],
    utlityLinks: [],
    searchBarLink: {
      name: "Search",
      url: "/search",
    },
    mobileUtilityLinks: {
      // login: {
      //   name: "Login",
      //   url: "/",
      // },
    },
  },
  Footer: [
    // make sure to update following css grid token if changing the number of items
    // --header-mega-menu-footer-items-grid-template
    {
      text: "ABOUT US",
      url: "/about-us",
    },
    {
      text: "ADVERTISE WITH US",
      url: "https://www.sph.com.sg/advertising-solutions/",
      target: "_blank",
    },
    {
      text: "CONTACT",
      url: "https://www.sph.com.sg/contact-us/",
      target: "_blank",
    },
    {
      text: "CONDITIONS OF ACCESS",
      url: "https://www.sph.com.sg/legal/website_tnc/",
      target: "_blank",
    },
    {
      text: "PDPA",
      url: "https://www.sph.com.sg/legal/pdpa/",
      target: "_blank",
    },
    {
      text: "PRIVACY POLICY",
      url: "https://www.sph.com.sg/legal/sph_privacy/",
      target: "_blank",
    },
  ],
  MegaMenu: [
    {
      text: "Interior Design",
      url: "/interior-design",
      placement: "left",
      subMenu: [
        {
          text: "HDB",
          url: "/interior-design/hdb",
        },
        {
          text: "Condominium",
          url: "/interior-design/condominium",
        },
        {
          text: "Landed",
          url: "/interior-design/landed",
        },
      ],
    },
    {
      text: "Property",
      url: "/property",
      placement: "right",
      subMenu: [
        {
          text: "HDB",
          url: "/property/hdb",
        },
        {
          text: "Condominium",
          url: "/property/condominium",
        },
        {
          text: "Rent",
          url: "/property/rent",
        },
      ],
    },
    {
      text: "Finance",
      url: "/finance",
      placement: "left",
      subMenu: [
        {
          text: "Mortgage",
          url: "/finance/mortgage",
        },
        {
          text: "Insurance",
          url: "/finance/insurance",
        },
        {
          text: "Renovation Loan",
          url: "/finance/renovation-loan",
        },
      ],
    },
    {
      text: "Renovation",
      url: "/renovation",
      placement: "right",
      subMenu: [
        {
          text: "Interior Designers",
          url: "/renovation/interior-designer",
        },
        {
          text: "Lighting",
          url: "/renovation/lighting",
        },
        {
          text: "Kitchen",
          url: "/renovation/kitchen",
        },
        {
          text: "Bathroom",
          url: "/renovation/bathroom",
        },
        {
          text: "Bedroom",
          url: "/renovation/bedroom",
        },
      ],
    },
    {
      text: "Shopping",
      url: "/shopping",
      placement: "left",
      subMenu: [
        {
          text: "Deals",
          url: "/shopping/deals",
        },
        {
          text: "Furniture",
          url: "/shopping/furniture",
        },
        {
          text: "Kitchen",
          url: "/shopping/kitchen",
        },
        {
          text: "Bathroom",
          url: "/shopping/bathroom",
        },
        {
          text: "Bedroom",
          url: "/shopping/bedroom",
        },
        {
          text: "Technology",
          url: "/shopping/technology",
        },
        {
          text: "Baby & Kids",
          url: "/shopping/baby-kids",
        },
        {
          text: "Reviews",
          url: "/shopping/review",
        },
      ],
    },
    {
      text: "How To",
      url: "/how-to",
      placement: "right",
      subMenu: [
        {
          text: "Cleaning",
          url: "/how-to/cleaning",
        },
        {
          text: "Home Repair",
          url: "/how-to/home-repair",
        },
      ],
    },
    // {
    //   text: "Ask the Experts",
    //   url: "/ask-the-experts",
    //   placement: "right",
    //   subMenu: [],
    // },
  ],
};
