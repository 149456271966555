import ErrorLayout from "@app/layouts/ErrorLayout/ErrorLayout";
import MetaData from "@components/Modules/MetaData";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import AuthorBlock from "@src/app/components/Modules/AuthorBlock/AuthorBlock";
import { STATIC_PAGE } from "@src/app/constants";
import { ResovledAuthorProfileElement } from "@src/app/layouts/Article/elements";
import Storyline from "@src/app/layouts/Article/Storyline";
import { StaticContent } from "@src/app/types/ServerCustomContext";
import cx from "classnames";
import { ReactNode } from "react";

import PubBaseLayout from "../BaseLayout";
import styles from "./aboutus.module.css";

type AboutUsData = StaticContent;

export default function AboutUsPage(): ReactNode {
  const routeContext: CustomContext<TRouteWithRedirect<AboutUsData, string>> =
    useRouteContext();

  const { context: response } = routeContext;

  if (response.type !== ResponseType.SUCCESS) {
    return <ErrorLayout response={response} />;
  }

  const { processedElements } = response.payload;
  const { headline, coverImage, bodyElements } = processedElements;

  const profileElements = bodyElements.filter(
    (e): e is ResovledAuthorProfileElement => e.type === "relation_profile",
  );

  let profileInjectionIndex = bodyElements.findIndex(
    (e) => e.type === "relation_profile",
  );

  if (
    profileInjectionIndex > 0 &&
    bodyElements[profileInjectionIndex - 1].type === "sub_head"
  ) {
    profileInjectionIndex = profileInjectionIndex - 1;
  }

  const withoutProfiles = bodyElements.filter(
    (e) => e.type !== "relation_profile",
  );

  return (
    <PubBaseLayout ads={false}>
      <MetaData
        title="About Us"
        type="WebPage"
        path="/about-us"
        image={{
          src: coverImage?.crops.landscape?.src || "",
          alt: "",
          srcWidth: 1366,
          srcHeight: 400,
        }}
      />
      <div
        className={styles.hndHeadingBackgroundContainer}
        style={{
          backgroundImage: `url(${coverImage?.crops.landscape?.src})`,
        }}
      >
        <div className={styles.hndHeadingContainer}>{headline}</div>
      </div>
      <div
        className={cx(
          styles.hndAboutContainer,
          styles.gutterTop,
          styles.gutterSides,
        )}
      >
        <Storyline
          bodyMaxWidth={800}
          path="/about-us"
          typeName={STATIC_PAGE}
          excludeAds={true}
          elements={withoutProfiles.slice(0, profileInjectionIndex)}
        />
      </div>
      {profileInjectionIndex > 0 && (
        <AuthorBlock
          data={profileElements}
          profileHeadlineElement={bodyElements[profileInjectionIndex]}
        />
      )}
      <div
        className={cx(
          styles.hndAboutContainer,
          styles.gutterBottom,
          styles.gutterSides,
        )}
      >
        <Storyline
          bodyMaxWidth={800}
          path="/about-us"
          typeName={STATIC_PAGE}
          excludeAds={true}
          elements={withoutProfiles.slice(profileInjectionIndex)}
        />
      </div>
    </PubBaseLayout>
  );
}
