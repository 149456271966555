const facebookIcon = (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="black" />
    <path
      d="M25.8465 23.6845L26.3651 20.2497H23.1234V18.0178C23.1234 17.0786 23.5747 16.1605 25.027 16.1605H26.5V13.2322C25.6338 13.0897 24.7624 13.0106 23.8859 13C21.2147 13 19.4668 14.6462 19.4668 17.6273V20.2497H16.5V23.6898H19.4668V32H23.1183V23.6898H25.8413L25.8465 23.6845Z"
      fill="white"
    />
  </svg>
);

const xIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="44"
    height="44"
    viewBox="0 0 44 44"
  >
    <path
      fill="#000"
      d="M44 22c0 12.1503-9.8497 22-22 22C9.84974 44 0 34.1503 0 22 0 9.84974 9.84974 0 22 0c12.1503 0 22 9.84974 22 22Z"
    />
    <path
      fill="#fff"
      d="M23.7123 20.6217 30.4132 13h-1.5879l-5.8183 6.6179L18.3599 13H13l7.0273 10.0074L13 31h1.588l6.1444-6.9887L25.6401 31H31l-7.2878-10.3783h.0004-.0003Zm-2.175 2.4738-.712-.9965-5.6652-7.9294h2.439l4.572 6.3993.712.9964 5.943 8.318h-2.439l-4.8496-6.7876v-.0004l-.0002.0002Z"
    />
  </svg>
);

const whatsappIcon = (
  <svg
    width="43"
    height="44"
    viewBox="0 0 43 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43 22C43 33.8741 33.3741 43.5 21.5 43.5C9.62588 43.5 0 33.8741 0 22C0 10.1259 9.62588 0.5 21.5 0.5C33.3741 0.5 43 10.1259 43 22Z"
      fill="black"
    />
    <path
      d="M28.304 15.1156C26.6205 13.4281 24.3786 12.5 21.996 12.5C17.0781 12.5 13.0763 16.5018 13.0763 21.4196C13.0763 22.9906 13.4862 24.5254 14.2656 25.8795L13 30.5L17.729 29.2585C19.0308 29.9696 20.4973 30.3433 21.992 30.3433H21.996C26.9098 30.3433 31 26.3415 31 21.4237C31 19.0411 29.9875 16.8031 28.304 15.1156ZM21.996 28.8406C20.6621 28.8406 19.3562 28.483 18.2192 27.808L17.95 27.6473L15.1455 28.3826L15.8929 25.6464L15.7161 25.3652C14.9728 24.1839 14.583 22.8219 14.583 21.4196C14.583 17.3335 17.9098 14.0067 22 14.0067C23.9808 14.0067 25.8411 14.7781 27.2393 16.1804C28.6375 17.5826 29.4973 19.4429 29.4933 21.4237C29.4933 25.5138 26.0821 28.8406 21.996 28.8406ZM26.0621 23.2879C25.8411 23.1754 24.7442 22.6371 24.5393 22.5647C24.3344 22.4884 24.1857 22.4522 24.0371 22.6772C23.8884 22.9022 23.4625 23.4004 23.3299 23.5531C23.2013 23.7018 23.0688 23.7219 22.8478 23.6094C21.5379 22.9545 20.6781 22.4402 19.8143 20.9576C19.5853 20.5638 20.0433 20.592 20.4692 19.7402C20.5415 19.5915 20.5054 19.4629 20.4491 19.3504C20.3929 19.2379 19.9469 18.1411 19.7621 17.6951C19.5813 17.2612 19.3964 17.3214 19.2598 17.3134C19.1312 17.3054 18.9826 17.3054 18.8339 17.3054C18.6853 17.3054 18.4442 17.3616 18.2393 17.5826C18.0344 17.8076 17.4598 18.346 17.4598 19.4429C17.4598 20.5397 18.2594 21.6004 18.3679 21.7491C18.4804 21.8978 19.9388 24.1478 22.1768 25.1161C23.5911 25.7268 24.1455 25.779 24.8527 25.6746C25.2826 25.6103 26.1705 25.1362 26.3554 24.6138C26.5402 24.0915 26.5402 23.6455 26.4839 23.5531C26.4317 23.4527 26.283 23.3964 26.0621 23.2879Z"
      fill="white"
    />
  </svg>
);

const pinterestIcon = (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="black" />
    <path
      d="M18.688 31.563C19.6 31.8385 20.5215 32 21.5 32C24.0196 32 26.4359 30.9991 28.2175 29.2175C29.9991 27.4359 31 25.0196 31 22.5C31 21.2524 30.7543 20.0171 30.2769 18.8645C29.7994 17.7119 29.0997 16.6646 28.2175 15.7825C27.3354 14.9003 26.2881 14.2006 25.1355 13.7231C23.9829 13.2457 22.7476 13 21.5 13C20.2524 13 19.0171 13.2457 17.8645 13.7231C16.7119 14.2006 15.6646 14.9003 14.7825 15.7825C13.0009 17.5641 12 19.9804 12 22.5C12 26.5375 14.5365 30.005 18.118 31.373C18.0325 30.632 17.947 29.4065 18.118 28.561L19.2105 23.868C19.2105 23.868 18.935 23.317 18.935 22.443C18.935 21.132 19.752 20.1535 20.683 20.1535C21.5 20.1535 21.88 20.752 21.88 21.5215C21.88 22.3385 21.3385 23.507 21.063 24.628C20.9015 25.559 21.557 26.376 22.507 26.376C24.198 26.376 25.509 24.571 25.509 22.025C25.509 19.745 23.875 18.187 21.5285 18.187C18.8495 18.187 17.2725 20.182 17.2725 22.2815C17.2725 23.0985 17.5385 23.925 17.9755 24.4665C18.061 24.5235 18.061 24.5995 18.0325 24.742L17.757 25.7775C17.757 25.939 17.6525 25.996 17.491 25.882C16.275 25.35 15.572 23.621 15.572 22.2245C15.572 19.2225 17.7 16.496 21.804 16.496C25.072 16.496 27.618 18.8425 27.618 21.9585C27.618 25.2265 25.5945 27.8485 22.697 27.8485C21.7755 27.8485 20.873 27.3545 20.55 26.775L19.9135 29.0265C19.695 29.8435 19.0965 30.936 18.688 31.5915V31.563Z"
      fill="white"
    />
  </svg>
);

const emailIcon = (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="black" />
    <path
      d="M31.0615 15H14.1C12.0104 15 12.0106 15.6595 12.0105 16.8136L12 27.6955C12 29.3442 12.3231 29.5091 14.1 29.5091H31.0615C32.6769 29.5091 33 29.5091 33 27.6955V16.9785C33 15.3298 33 15 31.0615 15ZM32.9975 18.2975L22.5 25.5521L12.0105 18.6273V16.8136L22.5 23.7384L32.9975 16.4839V18.2975Z"
      fill="white"
    />
  </svg>
);

export { emailIcon, facebookIcon, pinterestIcon, whatsappIcon, xIcon };
