const pinterestIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="44"
    height="44"
    viewBox="0 0 44 44"
  >
    <circle cx="22" cy="22" r="22" fill="#000" />
    <path
      fill="#fff"
      d="M18.688 31.563c.912.2755 1.8335.437 2.812.437 2.5196 0 4.9359-1.0009 6.7175-2.7825C29.9991 27.4359 31 25.0196 31 22.5c0-1.2476-.2457-2.4829-.7231-3.6355a9.49999 9.49999 0 0 0-2.0594-3.082 9.49999 9.49999 0 0 0-3.082-2.0594A9.50023 9.50023 0 0 0 21.5 13c-1.2476 0-2.4829.2457-3.6355.7231a9.49999 9.49999 0 0 0-3.082 2.0594C13.0009 17.5641 12 19.9804 12 22.5c0 4.0375 2.5365 7.505 6.118 8.873-.0855-.741-.171-1.9665 0-2.812l1.0925-4.693s-.2755-.551-.2755-1.425c0-1.311.817-2.2895 1.748-2.2895.817 0 1.197.5985 1.197 1.368 0 .817-.5415 1.9855-.817 3.1065-.1615.931.494 1.748 1.444 1.748 1.691 0 3.002-1.805 3.002-4.351 0-2.28-1.634-3.838-3.9805-3.838-2.679 0-4.256 1.995-4.256 4.0945 0 .817.266 1.6435.703 2.185.0855.057.0855.133.057.2755l-.2755 1.0355c0 .1615-.1045.2185-.266.1045-1.216-.532-1.919-2.261-1.919-3.6575 0-3.002 2.128-5.7285 6.232-5.7285 3.268 0 5.814 2.3465 5.814 5.4625 0 3.268-2.0235 5.89-4.921 5.89-.9215 0-1.824-.494-2.147-1.0735l-.6365 2.2515c-.2185.817-.817 1.9095-1.2255 2.565v-.0285Z"
    />
  </svg>
);

const appleIcon = (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="black" />
    <path
      d="M26.1647 29.3179C25.2601 30.215 24.2724 30.0734 23.3217 29.6485C22.3156 29.2141 21.3925 29.1952 20.331 29.6485C19.0018 30.2339 18.3003 30.064 17.5065 29.3179C13.002 24.568 13.6666 17.3345 18.7803 17.07C20.0264 17.1361 20.8941 17.7688 21.6233 17.8255C22.7125 17.5989 23.7555 16.9473 24.9186 17.0323C26.3124 17.1456 27.3646 17.7122 28.0569 18.7321C25.177 20.4979 25.8601 24.3791 28.5 25.4651C27.9739 26.8816 27.2908 28.2886 26.1554 29.3274L26.1647 29.3179ZM21.531 17.0134C21.3925 14.9075 23.0632 13.17 24.9832 13C25.2509 15.4364 22.8232 17.2495 21.531 17.0134Z"
      fill="white"
    />
  </svg>
);

const googlePlaystoreIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="44"
    height="44"
    viewBox="0 0 44 44"
  >
    <circle cx="22" cy="22" r="22" fill="#000" />
    <path
      fill="#fff"
      d="m17.4939 15.1548 7.0343 6.7907-7.0336 6.7906c-.125-.0507-.2318-.136-.3068-.2452-.0749-.1091-.1148-.2372-.1146-.3681v-12.354c0-.1309.0398-.2589.1146-.3681.0748-.1092.1813-.1947.3061-.2459Zm7.5227 7.262 1.5902 1.5347-7.5551 4.222 5.9649-5.7567Zm2.2099-2.132 1.939 1.084c.1047.0586.1916.1427.252.2439.0604.1012.0922.216.0922.3328 0 .1167-.0318.2315-.0922.3327-.0604.1012-.1473.1853-.252.2439l-1.9397 1.084-1.7208-1.6606 1.7215-1.6607Zm-8.1748-4.5673 7.5558 4.2213-1.5909 1.5353-5.9649-5.7566Z"
    />
  </svg>
);

const instagramIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="44"
    height="44"
    viewBox="0 0 44 44"
  >
    <circle cx="22" cy="22" r="22" fill="#000" />
    <g fill="#fff">
      <path d="M26.4162 30h-8.8326C15.3318 30 13.5 28.0762 13.5 25.7116v-8.4231C13.5 14.9238 15.3318 13 17.5836 13h8.8326c2.252 0 4.0838 1.9238 4.0838 4.2885v8.4231C30.5 28.0762 28.6682 30 26.4162 30Zm-8.8326-15.7096c-1.5741 0-2.8549 1.3451-2.8549 2.9981v8.4231c0 1.6531 1.2808 2.998 2.8549 2.998h8.8326c1.5741 0 2.8551-1.3449 2.8551-2.998v-8.4231c0-1.653-1.2808-2.9981-2.8551-2.9981h-8.8326Z" />
      <path d="M21.9998 26.1754c-2.4654 0-4.471-2.1063-4.471-4.6954 0-2.5892 2.0056-4.6953 4.471-4.6953 2.4653 0 4.4709 2.1062 4.4709 4.6953 0 2.589-2.0056 4.6954-4.4709 4.6954Zm0-8.1003c-1.7878 0-3.2423 1.5274-3.2423 3.4047s1.4545 3.4051 3.2423 3.4051c1.7877 0 3.2422-1.5276 3.2422-3.4051s-1.4545-3.4047-3.2422-3.4047Zm4.9673-.8389c.4015 0 .7269-.3418.7269-.7634s-.3254-.7633-.7269-.7633c-.4014 0-.7269.3417-.7269.7633s.3255.7634.7269.7634Z" />
    </g>
  </svg>
);

const facebookIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="44"
    height="44"
    viewBox="0 0 44 44"
  >
    <circle cx="22" cy="22" r="22" fill="#000" />
    <path
      fill="#fff"
      d="m25.8465 23.6845.5186-3.4348h-3.2417v-2.2319c0-.9392.4513-1.8573 1.9036-1.8573H26.5v-2.9283c-.8662-.1425-1.7376-.2216-2.6141-.2322-2.6712 0-4.4191 1.6462-4.4191 4.6273v2.6224H16.5v3.4401h2.9668V32h3.6515v-8.3102h2.723l.0052-.0053Z"
    />
  </svg>
);

const tiktokIcon = (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="black" />
    <path
      d="M21.935 13.0158C23.0144 12.9985 24.0847 13.0072 25.1595 12.9985C25.191 14.2337 25.7082 15.4127 26.6031 16.2981C27.5701 17.1316 28.8023 17.6326 30.0975 17.7146V20.9062C28.9012 20.8803 27.723 20.6169 26.6346 20.1375C26.1714 19.9259 25.7262 19.6797 25.299 19.4033C25.29 21.7138 25.308 24.0287 25.281 26.3306C25.218 27.4492 24.8312 28.5289 24.1702 29.4488C23.0593 30.9906 21.2515 31.9364 19.2952 31.9925C18.1169 32.0444 16.9431 31.7593 15.9312 31.1763C14.2403 30.2132 13.125 28.5375 12.9226 26.6545C12.9046 26.2572 12.8956 25.8642 12.9136 25.4755C13.2509 22.2019 16.291 19.8093 19.7044 20.1332C19.8393 20.1461 19.9742 20.1634 20.1092 20.185C20.1272 21.3554 20.0777 22.5301 20.0777 23.7005C18.6026 23.2038 16.9881 23.9466 16.4709 25.3632C16.4709 25.3675 16.4709 25.3718 16.4664 25.3762C16.336 25.7908 16.3 26.2226 16.3495 26.6502C16.5699 28.002 17.8111 28.978 19.2367 28.9219C20.1721 28.896 21.0311 28.4166 21.5213 27.6478C21.7057 27.4017 21.8226 27.1123 21.8586 26.81C21.9395 25.3934 21.9081 23.9812 21.917 22.5646C21.926 19.3731 21.9081 16.1901 21.935 13.0072V13.0158Z"
      fill="white"
    />
  </svg>
);

export {
  appleIcon,
  facebookIcon,
  googlePlaystoreIcon,
  instagramIcon,
  pinterestIcon,
  tiktokIcon,
};
